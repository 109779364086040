import React from "react";
import { StandingOrder } from "../../types/FinanceData";
import { formatBalance } from "../../utils/formatBalance";
import "./StandingOrders.css";

interface Props {
    standingOrders: Array<StandingOrder>;
}

export const StandingOrders: React.FC<Props> = ({ standingOrders }) => (
    <div className="container">
        <h2 className="title">Standing Orders</h2>
        <table>
            <thead>
                <tr>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Next Payment</th>
                </tr>
            </thead>
            <tbody>
                {standingOrders.map((standingOrder, index) => (
                    <tr key={index}>
                        <td>{standingOrder.reference}</td>
                        <td>{formatBalance(standingOrder.amount.minorUnits)}</td>
                        <td>{standingOrder.nextDate}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
