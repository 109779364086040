import React from "react";
import "./Transactions.css";
import { Direction, Status, Transaction } from "../../types/FinanceData";
import { formatBalance } from "../../utils/formatBalance";

interface Props {
    transactions: Transaction[];
}

export const Transactions: React.FC<Props> = ({ transactions }) => (
    <div className="container">
        <h2 className="title">Transactions</h2>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Transaction Time</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map((transaction, index) => (
                    <tr key={index} className={getRowClass(transaction.status)}>
                        <td>{transaction.counterPartyName}</td>
                        <td>{transaction.reference}</td>
                        <td className={getClass(transaction.direction)}>
                            {getSymbol(transaction.direction)}
                            {formatBalance(transaction.amount.minorUnits)}
                        </td>
                        <td>{formatTime(transaction.transactionTime)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const getRowClass = (status: Status): string => (status === "UPCOMING" ? "futureTransaction" : "");

const getSymbol = (direction: Direction): string => (direction === "IN" ? "+" : "-");

const getClass = (direction: Direction): string => (direction === "IN" ? "symbolPositive" : "symbolNegative");

const formatTime = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };
    return date.toLocaleTimeString("en-GB", options);
};
