import React from "react";
import { FetchData, Status } from "../../service/BankData";
import { AccountBalance } from "../AccountBalance/AccountBalance";
import { DirectDebits } from "../DirectDebits/DirectDebits";
import { Header } from "../Header/Header";
import { StandingOrders } from "../StandingOrders/StandingOrders";
import { Transactions } from "../Transactions/Transactions";
import { UserTracker } from "../UserTracker/UserTracker";
import "./App.css";

const App: React.FC = () => {
    const { data, status } = FetchData();

    if (status === Status.Loading) {
        return <div>Fetching data...</div>;
    }

    if (status === Status.Error) {
        return <div>Error retrieving data</div>;
    }

    return (
        <>
            <Header />
            <div className="App">
                <AccountBalance amount={data!.balance.amount} />
                <UserTracker transactions={data!.transactions} />
                <Transactions transactions={data!.transactions} />
                <StandingOrders standingOrders={data!.standingOrders} />
                <DirectDebits directDebits={data!.directDebits} />
            </div>
        </>
    );
};

export default App;
