import React from "react";
import "./UserTracker.css";
import { Transaction } from "../../types/FinanceData";
import { formatBalance } from "../../utils/formatBalance";
import moment from "moment";
import { userMapper } from "../../utils/userMapper";

interface Props {
    transactions: Transaction[];
}

export const UserTracker: React.FC<Props> = ({ transactions }) => (
    <div className="user-tracker">
        {Array.from(userMapper.keys()).map((name, index) => (
            <div key={index} className="container">
                <div>
                    <strong>{name}</strong>
                </div>
                <div>{formatBalance(getTransactions(name, transactions))}</div>
            </div>
        ))}
    </div>
);

function getTransactions(name: string, transactions: Transaction[]): number {
    return transactions
        .filter((transaction) => {
            const cutOffDate = moment().subtract(10, "days").unix();
            const transactionTime = moment(transaction.transactionTime).unix();

            const user = userMapper.get(name).toLowerCase().replace(/\s+/g, "");
            const counterPartyName = transaction.counterPartyName.toLowerCase().replace(/\s+/g, "");

            return user === counterPartyName && transactionTime > cutOffDate && transaction.direction === "IN";
        })
        .map((transaction) => transaction.amount.minorUnits)
        .reduce((sum, amount) => sum + amount, 0);
}
