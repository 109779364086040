import React from "react";
import { DirectDebit } from "../../types/FinanceData";
import { formatBalance } from "../../utils/formatBalance";
import "./DirectDebits.css";

interface Props {
    directDebits: Array<DirectDebit>;
}

const UNKNOWN = "UNKNOWN";

export const DirectDebits: React.FC<Props> = ({ directDebits }) => (
    <div className="container">
        <h2 className="title">Direct Debits</h2>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Last Payment Date</th>
                    <th>Last Payment Amount</th>
                </tr>
            </thead>
            <tbody>
                {directDebits.map((directDebit, index) => (
                    <tr key={index}>
                        <td>{directDebit.originatorName}</td>
                        <td>{directDebit.lastDate ?? UNKNOWN}</td>
                        <td>
                            {directDebit.lastPayment
                                ? formatBalance(directDebit.lastPayment.lastAmount.minorUnits)
                                : UNKNOWN}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);
