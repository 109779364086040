import React from "react";
import "./Header.css";
import { Logo } from "./Logo";

export const Header: React.FC = () => (
    <header className="App-header">
        <span className="headerTitle">Arodene Finance</span>
        <Logo />
    </header>
);
